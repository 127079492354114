import React, { useState } from "react";
import "./app.scss";
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from "@reach/router";
import { CONVEX_API_URL } from "../../../../config/config";

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: '#7e7e7e',
  marginTop: '-2px',
  '&.Mui-checked': {
    color: '#f1ae13',
  },
  '& .MuiSvgIcon-root': {
    fontFamily: 'Montserrat',
    fontSize: '18px', 
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: '0px', 
  '& .MuiTypography-root': {
    fontSize: '14px', 
    fontFamily: 'Montserrat', 
    marginBottom: '2px', 
  },
}));

const Card = (props) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    whatsapp_consent: 'yes',
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [value, setValue] = useState('yes');

  const handleChange = (event) => {
    setFormData({ ...formData, whatsapp_consent: event.target.value });
    setValue(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (!isNaN(value) || value !== "") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required";

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter valid email ID here";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (formData.phone.length !== 10 || !/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Must be of 10 digits";
    }

    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    }

    try {
      formData.source_page = location.href;
      formData.form_placement = props.placement;
      formData.utm_medium = 'blog';
      formData.utm_source = `${props.course}_form`;
      formData.program_name = props.course?.toUpperCase();
      formData.first_lead_stage = 'Prospect Lead';
      formData.additional_data = JSON.stringify({utm_Medium: formData.utm_Medium, utm_Source: formData.utm_Source});
      formData.lead_data = JSON.stringify({name: formData.name, email: formData.email, phone: formData.phone});
      formData.whatsapp_consent = formData.whatsapp_consent === 'yes' ? true : false;
      formData.form_page_type = `BLOG${props.course?.toUpperCase()}`;
      
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/blogs`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.status === 200) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="blog-sidebar-card">
      {!formSubmitted ? <>
        <div className="sidebar-title">
          {props.course === "mlp"
            ? "Start your Digital Marketing career"
            : "Start your career in Sales & Business"}
        </div>
        <div className="sidebar-desc">
          {props.course === "mlp"
            ? "Free 1-1 counseling, for jobs upto 9LPA"
            : "Free 1-1 counseling, for jobs upto 17LPA"}
        </div>

        <input
          className={`sidebar-input ${errors.name ? 'input-error' : ''}`}
          placeholder="Name*"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
        {errors.name && <p className="error-text">{errors.name}</p>}

        <input
          className={`sidebar-input ${errors.email ? 'input-error' : ''}`}
          placeholder="Email*"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        {errors.email && <p className="error-text">{errors.email}</p>}

        <input
          className={`sidebar-input ${errors.phone ? 'input-error' : ''}`}
          placeholder="Phone Number*"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleInputChange}
        />
        {errors.phone && <p className="error-text">{errors.phone}</p>}
        <div className="blog-block">
          <div className="whatsapp-concent">
        Get session details on{" "}
            <img
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m236s74v.webp"
              alt="whatsapp icon"
              className="whatsapp-sidebar"
              placeholder="none"
            />
          </div>

          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="choice"
              name="choice"
              value={value}
              onChange={handleChange}
            >
              <CustomFormControlLabel
                value="yes"
                control={<CustomRadio />}
                label="Yes"
              />
              <CustomFormControlLabel
                value="no"
                control={<CustomRadio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <button className="book-counsellor" onClick={handleSubmit}>
        Book my counselling
        </button>

      </> : <div className="formSubmitted">
        <img className="success-img" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yhv0tm654x2st.png"/>
        <div className="success-msg">Successful</div>
        <div className="success-desc">You will receive the call in 24-48 hours. Check your inbox for more details </div>
      </div>}
    </div>
  );
};

export default Card;
