import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import React from "react";
import "./styles.scss";
import { useLocation } from "@reach/router";
import Card from "./Card";
import './app.scss';

const index = ({ isLoading, isOpen,handleClose,placement,course }) => {
  const location = useLocation();

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      fullWidth
      scroll="body"
      className="lead-details-form-container-1"
      sx={{
        
        "& .MuiPaper-root": {
          //width: "100%",
          m: 0.5,
          //background: "#fff",
          borderRadius: "18px",
          maxWidth: "900px !important",
          "@media (max-width: 800px)": {
            maxWidth: "288px!important",
            background: 'none'
          },
        },
      }}
    >
      <DialogContent className="lead-details-lp-content blog-popup">
        {isLoading && (
          <div className="loader-container-main">
            <CircularProgress />
          </div>
        )}
       
     
        <div className="lp-p-popup">
          <button className="dialog-toggle-btn-blogs" onClick={handleClose}>
            {" "}
          ✕{" "}
          </button>
          <Card  course={course} placement={placement} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default index;
